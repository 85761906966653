<template>
    <svg id="Слой_1" data-name="Слой 1" viewBox="0 0 221.39 256.7" xmlns="http://www.w3.org/2000/svg">
        <title>ak-logo-square</title>
        <path class="cls-1"
              d="M129.79,79.9v5.15H102.47L94.23,65.46H52L43.71,85.05H16.39V79.9H30.31L65.36,0H80.83l35.05,79.9ZM88,52.58l-15.46-35L57.11,52.58Z"/>
        <path class="cls-2"
              d="M129.79,79.9V0h14.44l49.48,63.4V0h14.43V79.9H220v5.15H192.68L144.74,23.2V85.05H118.45V79.9Z"/>
        <path class="cls-3"
              d="M68.3,165V136.6L36.86,85.05H52.32l23.2,38.66L98.71,85.05h15.47L82.73,136.6V165h30.41v5.15H37.37V165Z"/>
        <path class="cls-4"
              d="M124.08,165V85.05h14.43v41.24l38.66-41.24h18l-34,37.11L192.12,165H204v5.15H178.71L150.36,133l-11.85,13.4V170.1H112.22V165Z"/>
        <path class="cls-5"
              d="M126.55,251.55v5.15H24.48v-5.15H52.32a40.44,40.44,0,0,1-15.46-15.47c-4.13-6.7-5.67-13.92-5.67-22.16,0-12.37,4.12-22.68,12.88-31.45S63.14,170.1,76,170.1s23.2,4.13,32,12.37,12.89,18.56,12.89,31.45a45.78,45.78,0,0,1-5.67,22.16,40.54,40.54,0,0,1-15.47,15.47Zm-29.9-59.8C91,185.57,83.76,183,75.52,183a29.2,29.2,0,0,0-21.14,8.76c-5.67,6.19-8.76,13.4-8.76,21.65a30.38,30.38,0,0,0,8.76,21.65c5.67,6.19,12.89,8.76,21.14,8.76a29.2,29.2,0,0,0,21.13-8.76,31.27,31.27,0,0,0,8.76-21.65A29.14,29.14,0,0,0,96.65,191.75Z"/>
        <path class="cls-6"
              d="M193.88,251.41c14.07-7,20.64-19.49,20.64-37.55,0-13.75-4.24-24.33-12.7-32.26S181.2,170,165.86,170H135.71v26.42h14.81v-13.2h14.81c22.74,0,34.38,10,34.38,30.15,0,10-2.65,17.45-8.47,22.22-5.81,5.28-13.75,7.4-24.32,7.4h-16.4V230.06H135.71v21.35H124.08v5.29h97.31v-5.29Z"/>
        <polygon class="cls-7"
                 points="154.77 209.47 152.2 204.31 144.46 209.47 144.98 200.19 139.31 200.19 139.82 209.47 132.61 204.31 129.51 209.47 137.76 213.07 129.51 217.2 132.61 221.84 140.34 216.68 139.31 225.93 144.98 225.93 144.46 217.2 152.2 222.35 154.77 217.2 146.53 213.07 154.77 209.47"/>
        <polygon class="cls-7"
                 points="25.26 209.47 22.68 204.31 14.95 209.47 15.46 200.19 9.79 200.19 10.31 209.47 3.09 204.31 0 209.47 8.25 213.07 0 217.2 3.09 221.84 10.82 216.68 9.8 225.93 15.46 225.93 14.95 217.2 22.68 222.35 25.26 217.2 17.01 213.07 25.26 209.47"/>
        <polygon class="cls-7"
                 points="88.14 209.47 85.57 204.31 77.83 209.47 78.35 200.19 72.68 200.19 73.2 209.47 65.98 204.31 62.89 209.47 71.13 213.07 62.89 217.2 65.98 221.84 73.71 216.68 72.68 225.93 78.35 225.93 77.83 217.2 85.57 222.35 88.14 217.2 79.9 213.07 88.14 209.47"/>
    </svg>
</template>

<script>
export default {}
</script>

<style scoped>
.cls-1 {
    fill: #a6b1db;
}

.cls-2 {
    fill: #f39042;
}

.cls-3 {
    fill: #dc2827;
}

.cls-4 {
    fill: #4bb05f;
}

.cls-5 {
    fill: #bfb2d8;
}

.cls-6 {
    fill: #0e84c7;
}

.cls-7 {
    fill: #010101;
    @apply dark:fill-slate-200
}
</style>
